export const LABEL_STEPS = ['Brand', 'SearchClient', 'TypeCurrency', 'DeliveryType'];

export const STEP_POSITION_CART = {
  Brand: {focus: {desktop: 280, mobile: 180}, position: 0, hasPackaging: false},
  SearchClient: {focus: {desktop: 220, mobile: 120}, position: 0, hasPackaging: false},
  TypeCurrency: {focus: {desktop: 220, mobile: 120}, position: 0, hasPackaging: false},
  AddProducts: {focus: {desktop: 280, mobile: 180}, position: 0, hasPackaging: false},
  ReturnablePackaging: {focus: {desktop: 220, mobile: 120}, position: 0, hasPackaging: false},
  Price: {focus: {desktop: 280, mobile: 160}, position: 0, hasPackaging: false},
  ScheduleDelivery: {focus: {desktop: 220, mobile: 120}, position: 0, hasPackaging: false},
  DeliveryType: {focus: {desktop: 280, mobile: 180}, position: 0, hasPackaging: false},
  Payment: {focus: {desktop: 220, mobile: 120}, position: 0, hasPackaging: false},
  Note: {focus: {desktop: 220, mobile: 120}, position: 0, hasPackaging: false}
};
