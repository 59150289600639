/* eslint-disable no-undef */
/* eslint-disable unicorn/prefer-query-selector */
import React, {useState, createContext, useContext, useCallback, useMemo, useEffect} from 'react';
import {getCurrentOrder} from '@oracle-cx-commerce/commerce-utils/selector';
import {useSelector} from '@oracle-cx-commerce/react-components/provider';
import {isEmptyObject} from '@oracle-cx-commerce/utils/generic';
import {useNavigator} from '@oracle-cx-commerce/react-components/link';
import {useMobile} from '../plugins/hooks/use-mobile';
import OcqJbqLoader from '../plugins/components/ocq-jbq-loader';
import {useOrganizations} from '../plugins/hooks/use-organization';
import {STEP_POSITION_CART} from '../plugins/contants';

const GlobalContext = createContext({});

export const GlobalProvider = ({children}) => {
  const navigator = useNavigator();
  const [finishLoadOrganizations, setFinishLoadOrganizations] = useState(false);
  const [isAcceptPrivacyPolicy, setAcceptPrivacyPolicy] = useState(false);
  const [stepPosition, setStepPosition] = useState(STEP_POSITION_CART);
  const [step, setStep] = useState(1);
  const [stepCheckout, setStepCheckout] = useState(null);
  const [products, setProducts] = useState();
  const [addNewProduct, setAddNewProduct] = useState(false);
  const [isToggleStepAddress, setToggleStepAddress] = useState(false);
  const [editProduct, setEditProduct] = useState({
    editStatus: false,
    commerceItem: null,
    productId: null,
    edited: {
      priceByKg: null,
      isLabeled: null,
      amountMaster: null,
      amountGram: null,
      returnable: null,
      unitPrice: null,
      isNational: null
    }
  });
  const [isLastProductOrder, setIsLastProductOrder] = useState(false);
  const [productHasBeenEdit, setProductHasBeenEdit] = useState(false);
  const [, , isPhone] = useMobile();
  const currentOrder = useSelector(getCurrentOrder);
  useOrganizations(setFinishLoadOrganizations, finishLoadOrganizations);

  const [addNewProductCheckout, setAddNewProductCheckout] = useState(false);
  const [hasExceededMinValue, setHasExceededMinValue] = useState(false);
  const [modalRemoveProducts, setModalRemoveProducts] = useState({isOpen: false, message: '', step: 0});
  const [isEnableLocalStore, setEnableLocalStore] = useState(false);
  const [pdtHasPackaging, setPdtHasPackaging] = useState();
  const [loading, setLoad] = useState(false);
  const [loadingTimeoutId, setLoadTimeoutId] = useState(undefined);

  const toggleStepCheckout = value => {
    setStepCheckout(value);
  };

  const toggleComponentFocus = (id, position) => {
    try {
      const element = document.getElementById(id);
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - position;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    } catch (error) {
      console.error('TAG - GLOBAL - toggleComponentFocus', error);
    }
  };

  const toggleFocus = useCallback((value, position) => {
    try {
      const id = `step${value}`;
      toggleComponentFocus(id, position);
    } catch (error) {
      console.error('TAG - GLOBAL - toggleFocus', error);
    }
  }, []);

  const getStepPosition = useCallback(
    (name, withPackage = true) => {
      const {position, hasPackaging} = typeof name === 'string' ? stepPosition[name] : name;

      return hasPackaging && !pdtHasPackaging && withPackage ? position - 1 : position;
    },
    [pdtHasPackaging, stepPosition]
  );

  const toggleStepStep = useCallback(
    value => {
      let positionStep;
      if (typeof value == 'number')
        positionStep = Object.values(stepPosition).find(step => getStepPosition(step) === value);
      else positionStep = stepPosition[value];

      const position = getStepPosition(positionStep);
      if (step === position || position <= 0) return;

      setStep(position);
      setTimeout(() => toggleFocus(position, positionStep.focus[isPhone ? 'mobile' : 'desktop']), 350);
    },
    [getStepPosition, step, stepPosition, toggleFocus, isPhone]
  );

  const toggleAddProduct = value => {
    try {
      setIsLastProductOrder(false);
      setAddNewProduct(value);
    } catch (error) {
      console.error('TAG - GLOBAL - toggleAddProduct', error);
    }
  };

  const lastPdtHasPackaging = useMemo(() => {
    if (!products?.length || products.length === 0 || !currentOrder?.commerceItems) return;

    const commerceItemsKeys = Object.keys(currentOrder?.commerceItems || {}) || [];
    const lastProductId = currentOrder?.commerceItems[commerceItemsKeys.pop()]?.productId;
    const lastProductOrder = products.find(pdt => pdt.id === lastProductId);

    return lastProductOrder?.x_returnablePackaging || false;
  }, [currentOrder?.commerceItems, products]);

  useEffect(() => {
    if (isEmptyObject(currentOrder) || !isToggleStepAddress || lastPdtHasPackaging == null) return;
    try {
      setIsLastProductOrder(true);
      setAddNewProduct(false);
      toggleStepStep('Payment');
    } catch (error) {
      console.error('TAG - toggleStepAddress', error);
    } finally {
      setToggleStepAddress(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrder, lastPdtHasPackaging, isToggleStepAddress]);

  useEffect(() => {
    if (isEnableLocalStore && addNewProductCheckout && step === 1) toggleStepStep('AddProducts');
  }, [isEnableLocalStore, addNewProductCheckout, step, toggleStepStep]);

  useEffect(() => {
    if (addNewProductCheckout) setToggleStepAddress(false);
  }, [addNewProductCheckout]);

  const toggleStepAddress = () => {
    setToggleStepAddress(true);
  };

  const clearPage = () => {
    setEnableLocalStore(false);
    localStorage.setItem('loadingId', JSON.stringify([]));
  };

  const toggleAddProductGoToCheckout = () => {
    toggleStepStep(1);
    setAddNewProductCheckout(false);
    clearPage();
    navigator('checkout-payment');
  };

  const setLoading = (visible, id = 'id') => {
    try {
      const loadingId = JSON.parse(localStorage.getItem('loadingId') || '[]');
      if (visible) {
        setLoad(true);
        localStorage.setItem('loadingId', JSON.stringify([...loadingId, id]));
        if (loadingTimeoutId) clearTimeout(loadingTimeoutId);
        setLoadTimeoutId(
          setTimeout(function () {
            setLoad(false);
            localStorage.setItem('loadingId', JSON.stringify([]));
          }, 10000)
        );
      } else {
        const loadingIdAux = loadingId.filter(e => e !== id);
        localStorage.setItem('loadingId', JSON.stringify(loadingIdAux));
        if (loadingIdAux.length === 0) {
          clearTimeout(loadingTimeoutId);
          setLoadTimeoutId(null);
          setLoad(false);
        }
      }
    } catch (error) {
      console.error('TAG - setLoading', error);
    }
  };

  return (
    <GlobalContext.Provider
      value={{
        step,
        toggleStepStep,
        toggleFocus,
        toggleComponentFocus,
        stepCheckout,
        toggleStepCheckout,
        addNewProduct,
        setAddNewProduct,
        editProduct,
        setEditProduct,
        toggleAddProduct,
        toggleStepAddress,
        isLastProductOrder,
        setIsLastProductOrder,
        addNewProductCheckout,
        setAddNewProductCheckout,
        toggleAddProductGoToCheckout,
        finishLoadOrganizations,
        setFinishLoadOrganizations,
        isAcceptPrivacyPolicy,
        setAcceptPrivacyPolicy,
        productHasBeenEdit,
        setProductHasBeenEdit,
        hasExceededMinValue,
        setHasExceededMinValue,
        modalRemoveProducts,
        setModalRemoveProducts,
        pdtHasPackaging,
        setPdtHasPackaging,
        setEnableLocalStore,
        setStepPosition,
        isEnableLocalStore,
        getStepPosition,
        setLoading,
        loading,
        setProducts
      }}
    >
      {children}
      <OcqJbqLoader isLoading={loading} />
    </GlobalContext.Provider>
  );
};

export function useStep(name, position, hasPackaging = false) {
  const context = useContext(GlobalContext);
  if (name != null && position != null) {
    const {getStepPosition, setStepPosition} = context;

    if (getStepPosition(name, false) !== position) {
      setStepPosition(step => {
        return {...step, [name]: {...step[name], position, hasPackaging}};
      });
    }
  }

  return context;
}
