import {useState, useEffect, useCallback} from 'react';

// eslint-disable-next-line no-undef
export const mobileScreenValidation = () => window.innerWidth <= 1024;
export const phoneScreenValidation = () => window.innerWidth < 768;

export const useMobile = () => {
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(true);
  const [isPhone, setPhone] = useState(false);

  const handleResize = useCallback(() => {
    if (mobileScreenValidation() !== isMobile || phoneScreenValidation() !== isPhone) {
      setLoading(true);
      setIsMobile(mobileScreenValidation());
      setPhone(phoneScreenValidation());
      setLoading(false);
    }
  }, [isMobile, isPhone]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [isMobile, loading, isPhone];
};
